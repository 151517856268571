/**
 *
 *  legal Section Style
 *
 *  @package ohkimur_layout
 *
 **/

section.legal-section {
    div.container {
        div.section-content {
            h3 {
                padding: 0px;
                margin-left: 0px;
                margin-bottom: 5px;
            }

            ul.legallist {
                margin-left: 30px;
                margin-top: -10px;
                padding: 5px;
                list-style-type: disc;
                list-style-position: outside;
            }

        }
    }

}